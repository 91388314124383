
(function () {
class Tutorial {
  screens = [];
  swipePaginationOptions = {
    countPages: 0,
    currentPage: 1,
    isMove: () => true
  }

  constructor($scope, $routeParams, AppFactory, APP_TUTORIAL) {
    Object.defineProperties(this, {
      $scope: { value: $scope },
      $routeParams: { value: $routeParams },
      AppFactory: { value: AppFactory }
    });

    let tutorialPagesCount;
    if (this.$routeParams.tutorial === 'tracking') tutorialPagesCount = 10;
    else if (this.$routeParams.tutorial === 'photo-document') tutorialPagesCount = 7;
    else if (this.$routeParams.tutorial === 'manual-tracking') tutorialPagesCount = 10;

    for (let i=1; i <= tutorialPagesCount; i++) {
      this.screens.push( [{
        image: `images/tutorials/${this.$routeParams.tutorial}/${this.$routeParams.tutorial}-${i}@2x.png`,
        text: `tutorial.${this.$routeParams.tutorial}.text_${i}`
      }] );
    }

    if (this.$routeParams.tutorial === 'tracking') {
      this.screens[6][0].image = this.screens[6][0].image.replace('7', '7-1');
      this.screens[6][0].text = this.screens[6][0].text.replace('7', '7-1');
      this.screens[6][0].title = `tutorial.${this.$routeParams.tutorial}.title_7`
      this.screens[6].push({
        image: `images/tutorials/${this.$routeParams.tutorial}/${this.$routeParams.tutorial}-7-2@2x.png`,
        text: `tutorial.${this.$routeParams.tutorial}.text_7-2`
      });
    }

    Object.assign(this,APP_TUTORIAL);
    this.swipePaginationOptions.countPages = this.screens.length;
    this.swipePaginationOptions.onChangePage = (num) => this.onChangePage(num)

    let passedTutorials = localStorage.getItem(`passedTutorials`) ? JSON.parse(localStorage.getItem(`passedTutorials`)) : [];
    if (!passedTutorials.find(t => t === this.$routeParams.tutorial)) passedTutorials.push(this.$routeParams.tutorial);
    localStorage.setItem(`passedTutorials`, JSON.stringify(passedTutorials));

    if (window.cordova && window.navigator.splashscreen) window.navigator.splashscreen.hide();
  }

  onChangePage(num) {
    this.swipePaginationOptions.currentPage = num;
    this.$scope.$apply();
  }

  toNext() {
    if (this.swipePaginationOptions.currentPage === this.swipePaginationOptions.countPages) this.skip();
    else this.swipePaginationOptions.currentPage = Math.min(this.swipePaginationOptions.currentPage + 1, this.swipePaginationOptions.countPages);
    //this.$scope.$apply();
  }

  skip() {
    this.AppFactory.goBack();
  }

  isCurrent(ind) {
    return ind + 1 === this.swipePaginationOptions.currentPage
  }
}

app.component('tutorial', {
    template: require('scripts/components/tutorials/tutorial/tutorial.html'),
    controller: Tutorial
});
})()
