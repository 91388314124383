class VacationRequestRoot {
  $scope:                 any;
  $rootScope:             any;
  $location:              any;
  $filter:                any;
  $timeout:               any;
  dbFactory:              any;
  pnListService:          any;
  vacationRequestService: any;
  vacationRequestFactory: any;

  loading:                boolean = false;

  height:                 string  = '0';
  isOpened:               boolean = false;

  unreadCount:            number;
  mappedVacations:        any[]   = [];
  reloadOpenVacations:    any;
  constructor($scope: any, $rootScope: any, $location: any, $filter: any, $timeout: any, DBFactory: any, PNListService: any, VacationRequestService: any, VacationRequestFactory: any) {
    Object.defineProperties(this, {
      $scope:                 { value: $scope                 },
      $rootScope:             { value: $rootScope             },
      $location:              { value: $location              },
      $filter:                { value: $filter                },
      $timeout:               { value: $timeout               },
      dbFactory:              { value: DBFactory              },
      pnListService:          { value: PNListService          },
      vacationRequestService: { value: VacationRequestService },
      vacationRequestFactory: { value: VacationRequestFactory },
    });
    this.loadOpenVacations();
    this.loadUnreadVacations();
    this.addWatchers();
  }

  private addWatchers() {
    this.$rootScope.$on('unreadReload', () => setTimeout(() => this.loadUnreadVacations()));
    this.reloadOpenVacations = this.$rootScope.$on('project.sync', (e) => {
      this.$timeout(() => { if (!this.loading) {
        this.close(true);
        this.loadOpenVacations();
      }});
    });
  }

  private loadUnreadVacations(): Promise<any> {
    return this.pnListService.getOwn(['vacation_request_approved', 'vacation_request_rejected'])
    .then(pn => this.unreadCount = pn.length)
    .then(() => this.$scope.$apply());
  }

  private loadOpenVacations(): Promise<any> {
    return this.vacationRequestService.getOpenVacationRequests()
    .then(res => res.map(r => new this.vacationRequestFactory.VacationRequestSubmitted(r)))
    .then(res => this.mapVacations(res))
    .then(() => this.$scope.$apply());
  }

  private mapVacations(vrList): void {
    this.mappedVacations = vrList.map(vr => ({
      id:        vr.id,
      title:    `${this.$filter('date')(vr.startsOn, 'dd-MM-yyyy')} - ${this.$filter('date')(vr.endsOn, 'dd-MM-yyyy')}`,
      subtitle:  vr.leaveType,
      url:     `/vr-new/${vr.id}`
    }));
    if (this.isOpened) this.open();
  }

  changeLocation(event, to): void {
    event.stopPropagation();
    this.$location.path(to);
  }

  openClose() {
    if (!this.isOpened) this.open();
    else this.close();
  }
  private open(): void {
    let folder = document.getElementById('vr-folder');
    let height = [...<any>folder.children].filter(el => el.localName === 'nested-list-item').reduce((sum, val) => sum = sum + +val?.children[0]?.offsetHeight, 0);
    folder.style.height = height + 'px';
    this.isOpened = true;
  }

  private close(reloading: boolean = null): void {
    let folder = document.getElementById('vr-folder');
    folder.style.height = '0px';
    if (!reloading) this.isOpened = false;
  }

  $onDestroy(): void {
    this.reloadOpenVacations && this.reloadOpenVacations();
  }

}

window.app.component('vrRoot', {
  template: require('scripts/components/vacation-request/root/vr-root.html'),
  controller: ['$scope', '$rootScope', '$location', '$filter', '$timeout', 'DBFactory', 'PNListService', 'VacationRequestService', 'VacationRequestFactory', VacationRequestRoot]
});
